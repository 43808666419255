
export const unlim = () => {
    let answer = false;
    if(sessionStorage.getItem('unlimited')){
      console.log('unlim started');
      answer = true;
    }

    return answer;
}

export const parseInput = (input) => {
    if (typeof input === "object") {
      return input;
    }

    if (typeof input === "string") {
      try {
        const json = JSON.parse(input);
        return json;
      } catch (e) {
        const jsonStartIndex = input.indexOf("{");
        const jsonEndIndex = input.lastIndexOf("}") + 1;

        if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
          const jsonString = input.substring(jsonStartIndex, jsonEndIndex);
          try {
            const json = JSON.parse(jsonString);
            return json;
          } catch (e) {
            throw new Error("Error parsing JSON.");
          }
        } else {
          throw new Error("JSON part not found in the string.");
        }
      }
    }

    throw new Error("Unknown input format.");
  }