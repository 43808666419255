import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import StartingPage from "./pages/StartingPage/StartingPage.jsx";
import Preferences from "./pages/Preferences/Preferences";
import Header from "./components/Header/Header.jsx";
import FavouritesPage from "./pages/FavouritesPage/FavouritesPage.jsx";
import LoginPage from "./components/LoginPage/LoginPage";
import Recipe from "./pages/Recipe/Recipe.jsx";
import SingleRecipe from "./components/SingleRecipe/SingleRecipe";
import Contact from "./pages/ContactPage/Contact";
import Terms from "./pages/TermsPage/Terms";

function App() {
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <div className="App">
      <Header/>
      {((pathName === '/single-recipe/') || (pathName === '/single-recipe')) ?
        <div className="favorite__absent">
          Nothing here yet!
          <br/> Use menu to generate a recipe
        </div> :
        <Routes>
          <Route path='/' element={<LoginPage/>}/>
          <Route path="starting-page" element={<StartingPage/>}/>
          <Route path="preferences" element={<Preferences/>}/>
          <Route path="favourites" element={<FavouritesPage/>}/>
          <Route path="recipe" element={<Recipe/>}/>
          <Route path='single-recipe/:id' element={<SingleRecipe/>}></Route>
          <Route path="contact" element={<Contact/>}/>
          <Route path="terms" element={<Terms/>}/>
        </Routes>}

    </div>
  );
}

export default App;
