const API_URL = "./get-receipt/";

export const fetchResponse = async (recipesData, sourcePage) => {
  // Формуємо об'єкт messages одразу

  console.log(sourcePage);
  let messages;
  let preview_query;

  function parseInput(input) {
    if (typeof input === "object") {
      return input;
    }

    if (typeof input === "string") {
      try {
        const json = JSON.parse(input);
        return json;
      } catch (e) {
        const jsonStartIndex = input.indexOf("{");
        const jsonEndIndex = input.lastIndexOf("}") + 1;

        if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
          const jsonString = input.substring(jsonStartIndex, jsonEndIndex);
          try {
            const json = JSON.parse(jsonString);
            return json;
          } catch (e) {
            throw new Error("Error parsing JSON.");
          }
        } else {
          throw new Error("JSON part not found in the string.");
        }
      }
    }

    throw new Error("Unknown input format.");
  }

  if (sourcePage === "preferences") {
    messages = [
      {
        role: "system",
        content: "You are a helpful assistant.",
      },
      {
        role: "user",
        content: `Create 1 recipe with data ${JSON.stringify({
          prompt: recipesData,
        })} and return only an object with the keys: "title", "time_preparing", "ingredients", and "instructions" that should be included relevant content (don't set number for instructions)`,
      },
    ];
  } else {
    const userConversation = localStorage.getItem("conversation");
    if (userConversation) {
      let except_titles = userConversation.split(" ");
      console.log(except_titles);
      console.log(typeof except_titles);
      messages = [
        {
          role: "system",
          content: "You are a helpful assistant.",
        },
        {
          role: "user",
          content: `Create another recipe with data ${JSON.stringify({
            prompt: recipesData,
          })}, excluding recipes with the following titles: ${except_titles} and return only an object with the keys: "title", "time_preparing", "ingredients" and "instructions" that should be included relevant content (don't set number for instructions)`,
        },
      ];
    }
  }

  // Формуємо об'єкт для відправки
  const requestData = {
    "api-key": "11223344", // Додаємо api-key
    messages: messages,
  };

  // Зберігаємо останній запит у локальному сховищі
  localStorage.setItem("last_query", JSON.stringify(recipesData));

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData), // Відправляємо requestData
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log(data.usage);
    //preview_query = JSON.parse(data.content);
    preview_query = parseInput(data.content);

    console.log(preview_query.title);

    //   || []
    const userConversation = localStorage.getItem("conversation");
    if (!userConversation) {
      console.error("User not made any query");
      let arr = [];
      arr.push(preview_query.title);
      console.log(JSON.stringify(arr));

      localStorage.setItem("conversation", JSON.stringify(arr));
    } else {
      let conversationTitles = JSON.parse(userConversation);
      console.log(conversationTitles);
      conversationTitles.push(preview_query.title);
      console.log(conversationTitles);
      localStorage.setItem("conversation", JSON.stringify(conversationTitles));
    }
    //

    return {
      status: 200,
      content: data.content,
      usage: data.usage,
    };
  } catch (error) {
    console.error("Error fetching response:", error);
    throw error;
  }
};
