import "../../fonts/fonts.css";
import "./header.css";
import "../../assets/css_variables.css";
import React, {useRef, useEffect, useState} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";
import cn from "classnames";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef(null);
  const navRef = useRef(null);
  const counterRef = useRef(null);
  const backButtonRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.style.overflow = "hidden";
      const userHash = localStorage.getItem("userHash");

      if (userHash) {
        const userHashDataString = localStorage.getItem(userHash);

        if (userHashDataString) {
          const userHashData = JSON.parse(userHashDataString);
          const currentDate = new Date().toLocaleDateString();

          // Check if the date stored is the same as today
          if (userHashData.date === currentDate) {
            const counterNumber = userHashData.generated_recipe_today;

            counterRef.current.textContent = counterNumber + "/5";
          } else {
            // If the date is different, reset the counter and update the date
            userHashData.date = currentDate;
            userHashData.generated_recipe_today = 0;
            localStorage.setItem(userHash, JSON.stringify(userHashData));

            counterRef.current.textContent = "0/5";
          }
        } else {
          // If user data does not exist, initialize it
          const initialData = {
            date: new Date().toLocaleDateString(),
            generated_recipe_today: 0,
            favorites: [],
          };
          localStorage.setItem(userHash, JSON.stringify(initialData));
          counterRef.current.textContent = "0/5";
        }
      } else {
        console.error("User hash not found in local storage");
        counterRef.current.textContent = "0/5"; // Handle the case where userHash is not found
      }
    } else {
      body.style.overflow = "";
    }

    // Cleanup effect on component unmount
    return () => {
      body.style.overflow = "";
    };
  }, [isOpen]);

  useEffect(() => {
    if (pathName === RoutesToPages.loginPage) {
      headerRef.current.classList.add("hide");
    } else {
      headerRef.current.classList.remove("hide");
    }

    if ((isOpen || isOpen === false) && pathName === RoutesToPages.home) {
      backButtonRef.current.classList.add("back-button__hidden");
    } else if (isOpen) {
      backButtonRef.current.classList.add("back-button__hidden");
    } else {
      backButtonRef.current.classList.remove("back-button__hidden");
    }
  }, [pathName, isOpen]);

  const userHash = localStorage.getItem("userHash");

  useEffect(() => {
    if (!userHash) {
      console.error("User hash not found in local storage");
      navigate(RoutesToPages.loginPage);
    } else {
      const arrayRoutes = Object.entries(RoutesToPages).map(
        ([key, value]) => value
      );

      // const arrPathsWithoutSingleRecipe = arrayRoutes
      //   .filter(route => route !== '/single-recipe/')

      if (!arrayRoutes.includes(pathName)) {
        if (!pathName.startsWith('/single-recipe') || pathName.endsWith('/single-recipe/')) {
          navigate(RoutesToPages.home);
        }
      }
    }
  }, [pathName]);

  const menuBtn = cn("menu__btn", {
    menu__btn_active: isOpen,
  });

  const menuNav = cn("menu__nav", {
    menu__nav_active: isOpen,
  });

  const headerHeight = () => {
    return headerRef.current.offsetHeight;
  };

  const handleMenuVisibility = () => {
    setIsOpen(!isOpen);
    navRef.current.style.top = headerHeight() + 1 + "px";
  };

  const menuRedirect = ({target}) => {
    setIsOpen(!isOpen);
    const URL = target.dataset.href;
    navigate(URL);
  };

  const navigatePreviousPage = () => {
    setIsOpen(false);
    navigate(-1);
  };

  const handleHomeNavigate = () => {
    if (pathName === RoutesToPages.home) {
      backButtonRef.current.classList.add("back-button__hidden");
      return;
    }
    setIsOpen(false);
    navigate(RoutesToPages.home);
  };

  return (
    <header
      ref={headerRef}
      id="header__wrapper"
      className="header__wrapper hide"
    >
      <div className="header">
        <div ref={backButtonRef} className="button__back_wrapper">
          <button
            className="button_back"
            onClick={navigatePreviousPage}
          ></button>
        </div>
        <div className="logo__wrapper">
          <img
            className="logo"
            onClick={handleHomeNavigate}
            src={require("./img/logo_mini.png")}
            alt="main logo"
          />
        </div>
        <menu>
          <div onClick={handleMenuVisibility} className={menuBtn}>
            <span></span>
          </div>

          <nav onClick={menuRedirect} ref={navRef} className={menuNav}>
            <h2 className="menu__title">Menu</h2>
            <div id="menu__block" className="menu__block">
              <button
                data-href={RoutesToPages.home}
                className="menu__item menu__item_home"
              >
                <span
                  data-href={RoutesToPages.home}
                  className="menu__item_icon"
                >
                  <img src={require("./img/home.png")} alt="icon home"/>
                </span>
                <span data-href={RoutesToPages.home}>Home</span>
              </button>
              <button
                data-href={RoutesToPages.favourites}
                className="menu__item menu__item_favourites"
              >
                <span
                  data-href={RoutesToPages.favourites}
                  className="menu__item_icon"
                >
                  <img
                    src={require("./img/favourites.png")}
                    alt="icon favourites"
                  />
                </span>
                <span data-href={RoutesToPages.favourites}>
                  Favorite Recipes
                </span>
              </button>
              <button
                data-href={RoutesToPages.contact}
                className="menu__item menu__item_contact"
              >
                <span
                  data-href={RoutesToPages.contact}
                  className="menu__item_icon"
                >
                  <img src={require("./img/postbox.png")} alt="icon postbox"/>
                </span>
                <span data-href={RoutesToPages.contact}>Contact us</span>
              </button>
              <button
                data-href={RoutesToPages.terms}
                className="menu__item menu__item_terms"
              >
                <span
                  data-href={RoutesToPages.terms}
                  className="menu__item_icon"
                >
                  <img src={require("./img/terms.png")} alt="icon terms"/>
                </span>
                <span data-href={RoutesToPages.terms}>Terms & Conditions</span>
              </button>
              <div className="attempts__counter">
                Generation attempts for today:{" "}
                <strong ref={counterRef}> 4/5</strong>
              </div>
            </div>
          </nav>
        </menu>
      </div>
    </header>
  );
};

export default Header;
