import { useNavigate } from "react-router-dom";
import RoutesToPages from "../RoutesToPages";

export function getFormattedDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

// export function CheckExistUser(){
//   const navigate = useNavigate();
//   const userHash = localStorage.getItem('userHash');

//   if (!userHash) {
//     console.error('User hash not found in local storage');
//     navigate(RoutesToPages.loginPage);
//     // console.log('Юзер - хакер');
//     return false;
//   }
//   // console.log('фальш старт, юзер зробив все вірно');
// }
  
export function CheckAndUpdateRecipeAttempts() {
    
    const userHash = localStorage.getItem('userHash');    
  
    const currentDate = getFormattedDate();
    let userData = JSON.parse(localStorage.getItem(userHash));
  
    if (!userData) {
      // Якщо даних немає, створюємо новий об'єкт
      userData = {
        date: currentDate,
        generated_recipe_today: 1,
        favourites: []
      };
    } else {
      // Перевіряємо, чи співпадає дата
      if (userData.date === currentDate) {
        // Перевіряємо, чи не перевищена кількість спроб
        if (userData.generated_recipe_today < 5) {
          userData.generated_recipe_today += 1;
        } else {
          console.log('Ви досягли максимальної кількості спроб на сьогодні.');
          //alert('Ви досягли максимальної кількості спроб на сьогодні.');
          return;
        }
      } else {
        // Якщо дата не співпадає, оновлюємо дату і скидаємо лічильник
        userData.date = currentDate;
        userData.generated_recipe_today = 1;
      }
    }
  
    // Зберігаємо оновлені дані в localStorage
    localStorage.setItem(userHash, JSON.stringify(userData));
}