import "./starting.css";
import "../../fonts/fonts.css";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";

const StartingPage = () => {
  const myRef = useRef(null);
  const navigate = useNavigate();

  const redirectToPreferences = () => {
    navigate(RoutesToPages.preferences);
  };

  return (
    <section>
      <div ref={myRef} className="starting__page_wrapper">
        <div className="starting__page">
          <div className="starting__logo_wrapper">
            <img
              className="starting__logo"
              src={require("./img/start.png")}
              alt="starting__logo"
            />
          </div>
          <div id="starting__text" className="starting__page_text">
            Let's cook something special! Answer a few questions, and I'll
            generate recipes for you.
          </div>
          <button
            className="starting__page_button"
            onClick={redirectToPreferences}
          >
            Start
          </button>
        </div>
      </div>
    </section>
  );
};

export default StartingPage;
