import "./contact.css";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FavoritesPage = () => {
  const navigate = useNavigate();

  const redirectToBack = () => {
    navigate(-1);
  };

  return (
    <section id="contact" className="section">
      <div className="section">
        <div className="contact__page_wrapper">
          <div className="contact__page">
            <h2 className="contact__page_title">Contact Us</h2>

            <div className="contact__logo_wrapper">
              <img
                className="contact__logo"
                src={require("./img/image.png")}
                alt="contact__logo"
              />
            </div>
            <div id="contact__text" className="contact__page_text">
              <p>
                The service provider is Propulse FZCO, IFZA Business Park, DDP,
                001 - 26165, Dubai Silicon Oasis, Dubai, United Arab Emirates
              </p>
              <p>
                <strong>Phone:</strong> +359 24928370
              </p>
              <p>
                <strong>Email:</strong> propulse.bg@silverlines.info
              </p>
            </div>

            <button className="contact__page_button" onClick={redirectToBack}>
              Back
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FavoritesPage;
