import "./favorite.css";
import React, { useState, useRef, useEffect } from "react";
import timerImg from "../Recipe/img/timer.svg";
import arrowOpen from "./img/arrow_open.svg";
import { useNavigate } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";

const FavoritesPage = () => {
  const recipeLikeRef = useRef(null);
  const userHash = localStorage.getItem("userHash");
  const userData = JSON.parse(localStorage.getItem(userHash));
  const [generalFavorites, setGeneralFavorites] = useState(
    userData ? userData.favorites : []
  );
  const navigate = useNavigate();

  const handleChangeLike = (id) => () => {
    const updatedRecipes = generalFavorites.map((item) =>
      item.id === id ? { ...item, liked: !item.liked } : item
    );

    setGeneralFavorites(updatedRecipes);
    userData.favorites = updatedRecipes;
    localStorage.setItem(userHash, JSON.stringify(userData));
  };

  useEffect(() => {
    if (userData) {
      const actualStoredRecipes = userData.favorites.filter(
        (item) => item.liked
      );
      userData.favorites = actualStoredRecipes;
      localStorage.setItem(userHash, JSON.stringify(userData));
      setGeneralFavorites(actualStoredRecipes);
    }
  }, []);

  const navigateToSingleRecipe = (recipeLiked, recipeId) => () => {
    if (recipeLiked) {
      navigate(RoutesToPages.singleRecipe + recipeId);
    }
  };

  return generalFavorites && generalFavorites.length > 0 ? (
    <section id="section__wrapper" className="section">
      <div className="section">
        <div className="favorites__page_wrapper">
          <div className="favorites__page">
            <h2 className="favorites__page_title">
              <span>
                <img src="" alt="" />
              </span>
              <span>Favorite Recipes</span>
            </h2>

            <div className="favorites__box">
              {generalFavorites.map((elem, index) => (
                <div
                  className="favorites__recipe"
                  key={index}
                  // id={elem.id}
                >
                  {Object.entries(elem).map(([key, value], index) => (
                    <React.Fragment key={index}>
                      {key === "title" && (
                        <>
                          <div className="favorites__title_wrapper">
                            <h3 className="favorites__title">{value}</h3>
                            <div
                              className={
                                elem.liked ? "recipe__liked" : "recipe__like"
                              }
                              ref={recipeLikeRef}
                              onClick={handleChangeLike(elem.id)}
                              id={elem.id}
                            ></div>
                          </div>
                        </>
                      )}
                      {key === "ingredients" && (
                        <>
                          <div className="ingredients__wrapper">
                            <span className="ingredients">Ingredients:</span>
                            <ul className="ingredients__list">
                              {value.slice(0, 3).map((item, index) => (
                                <li className="ul" key={index}>
                                  {index === value.slice(0, 3).length - 1
                                    ? item.concat(" ...")
                                    : item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      )}

                      {key === "time_preparing" && (
                        <>
                          <div className="divider__line" />
                          <div className="interaction__receipt">
                            <div className="receipt__time">
                              <span className="time">
                                <span>
                                  <img className="icon__time" src={timerImg} />
                                </span>
                                {value}
                              </span>
                            </div>{" "}
                            <a
                              onClick={navigateToSingleRecipe(
                                elem.liked,
                                elem.id
                              )}
                              className="open__receipt"
                            >
                              <img src={arrowOpen} className="arrow" />
                            </a>
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="favorite__absent">
      Nothing here yet!
      <br /> Like recipes to see them in this tab.
    </div>
  );
};

export default FavoritesPage;
