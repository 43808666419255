import "./single_recipe.css";
import React, {useEffect, useRef, useState} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";

const SingleRecipe = () => {
  const recipeLikeRef = useRef(null);
  const userHash = localStorage.getItem("userHash");
  const userData = JSON.parse(localStorage.getItem(userHash));
  const {id} = useParams();
  const [singleRecipeData, setSingleRecipeData] = useState(
    userData.favorites || []
  );
  const currentRecipe = singleRecipeData.find((el) => el.id === id);
  const navigate = useNavigate();
  const [isLiked, setIsLiked] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Використовуйте 'smooth' для плавної прокрутки
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleChangeLikeSingleRecipe = () => {
    recipeLikeRef.current.classList.toggle("recipe__liked");

    if (recipeLikeRef.current.classList.contains("recipe__liked")) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  };

  useEffect(() => {
    if (
      recipeLikeRef.current &&
      recipeLikeRef.current.classList.contains("recipe__liked")
    ) {
      if (!userData.favorites.some((fav) => fav.id === id)) {
        userData.favorites = [
          {
            liked: true,
            id: id,
            image: currentRecipe.image,
            title: currentRecipe.title,
            time_preparing: currentRecipe.time_preparing,
            ingredients: currentRecipe.ingredients,
            instructions: currentRecipe.instructions,
          },
          ...userData.favorites,
        ];
      }
    } else {
      userData.favorites = userData.favorites.filter((fav) => fav.id !== id);
    }

    localStorage.setItem(userHash, JSON.stringify(userData));
  }, [userData, isLiked]);

  useEffect(() => {
    if (!recipeLikeRef.current) {
      navigate(RoutesToPages.favourites);
    }
  }, []);

  return currentRecipe ? (
    <section className="recipe__section">
      <div className="recipe__page_wrapper">
        <div className="recipe__banner_wrapper">
          <img
            className="recipe__banner"
            src={require(`./img/${currentRecipe.image}.png`)}
            alt="recipe__logo"
          />
        </div>

        <div className="recipe__content">
          <>
            <h1 className="recipe__title">{currentRecipe.title}</h1>
            <div className="interactive__elements">
              <div className="recipe__timer">
                {currentRecipe.time_preparing}
              </div>
              <div
                className="recipe__like recipe__liked"
                ref={recipeLikeRef}
                onClick={handleChangeLikeSingleRecipe}
              ></div>
            </div>
            <h2 className="ingredients__title">Ingredients</h2>
            <ul>
              {currentRecipe.ingredients.map((ingredient, index) => (
                <li className="ul" key={index}>
                  {ingredient}
                </li>
              ))}
            </ul>
            <h2 className="instructions__title">Instructions</h2>
            <ol>
              {currentRecipe.instructions.map((instruction, index) => (
                <li className="ol" key={index}>
                  {instruction}
                </li>
              ))}
            </ol>
          </>
        </div>

        <div id="recipe__message" className="recipe__message">
          I'm just an AI. There might be mistakes in what I generate. Please
          check recipes before cooking.
        </div>

        <form className="form">
          <button
            type="submit"
            className="recipe__page_button"
            onClick={() => navigate(RoutesToPages.home)}
          >
            Finish
          </button>
        </form>
      </div>
    </section>
  ) : (
    <h1>No recipe</h1>
  );
};

export default SingleRecipe;
